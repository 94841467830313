import HomePage from "../Pages/HomePage";
import { FC } from "react";
import WatchListPage from "Pages/WatchListPage";
import ParseJsonModel from "Pages/ParseJsonModel";
import JSONViewer from "Pages/JSONViewerPage";
import MultiBrowser from "Pages/multiFrame";
import StockDetailsPage from "Pages/StockDetailsPage";
import CalculatorPage from "Pages/CalculatorPage";

export interface IRoute {
  path: string;
  pageComponent: FC;
  pageTitle?: string;
  template?: any;
  showSearchBar?: boolean;
  isEditting?: boolean;
  showHeading?: boolean;
}

const generalRoutes: IRoute[] = [
  {
    path: "/",
    pageComponent: HomePage,
    showSearchBar: true,
    showHeading: true,
  },
  {
    path: "/linh",
    pageComponent: HomePage,
    showSearchBar: true,
    showHeading: true,
  },
  {
    path: "/cv",
    pageComponent: HomePage,
    showSearchBar: true,
    showHeading: true,
  },
  // {
  //   path: "/wl",
  //   pageComponent: WatchListPage,
  //   showSearchBar: true,
  //   showHeading: true,
  // },
  {
    path: "/browser",
    pageComponent: MultiBrowser,
    showSearchBar: true,
    showHeading: true,
  },
  // {
  //   path: "/tools/jsonmodel",
  //   pageComponent: ParseJsonModel,
  //   showSearchBar: true,
  //   showHeading: true,
  // },
  // {
  //   path: "/tools/jsonViewer",
  //   pageComponent: JSONViewer,
  //   showSearchBar: true,
  //   showHeading: true,
  // },
  // {
  //   path: "/stock",
  //   pageComponent: StockDetailsPage,
  //   showSearchBar: true,
  //   showHeading: true,
  // },
  // {
  //   path: "/cal",
  //   pageComponent: CalculatorPage,
  //   showSearchBar: true,
  //   showHeading: true,
  // },
];

const getAvailableRoutes = (): IRoute[] => {
  return [...generalRoutes];
};

export default getAvailableRoutes;
