import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Flex, Heading, Link, Text, Image } from 'theme-ui';

export function ProjectView({ projectName, role, exp, link, appIcon }) {
  const [appUrl, setAppUrl] = useState(appIcon);

  return (
    <>
      <Box
        variant='text.header4'
        sx={{
          pt: '34px',
          textAlign: 'left',
          color: '#4A4A68',
          fontWeight: '700',
          textTransform: 'uppercase',
        }}
      >
        {projectName}
      </Box>
      <Box
        variant='text.header4'
        sx={{ textAlign: 'left', color: '#4A4A68', fontWeight: '500' }}
      >
        {role}
      </Box>
      <Box variant='text.header4' sx={{ textAlign: 'left', fontWeight: '400' }}>
        {exp}
      </Box>
      <Flex
        sx={{
          height: '52px',
          // justifyContent: "center",
          my: '24px',
          alignItems: 'center',
        }}
      >
        {/* {`${link.split(": ")[0]} `} */}
        <Link
          href={link.split(' ')[1]}
          variant='text.header4'
          sx={{
            textAlign: 'left',
            fontWeight: '600',
            textOverflow: 'ellipsis',
          }}
        >
          {appUrl && appUrl !== '' ? (
            <Flex
              sx={{
                width: '52px',
                mr: '24px',
                flexShrink: '0',
              }}
            >
              <Image
                src={appUrl}
                sx={{
                  width: '100%',
                  position: 'relative',
                  borderRadius: '4px',
                  overflow: 'hidden',
                }}
              />
            </Flex>
          ) : (
            <Box
              sx={{
                borderRadius: '4px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {link.split(' ')[1]}
            </Box>
          )}
        </Link>
      </Flex>
      <Box
        sx={{
          width: '32%',
          height: '4px',
          bg: 'rgba(105, 88, 26, 0.15)',
          my: '24px',
        }}
      />
    </>
  );
}
export function NameView() {
  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center', mb: 'auto' }}>
      <Box
        sx={{
          width: '100%',
          px: 'auto',
        }}
      >
        <Flex
          sx={{
            width: '80%',
            paddingTop: '100%' /* 1:1 Aspect Ratio */,
            mx: 'auto',
            mt: '20%',
            mb: '0',
            flexGrow: 2,
            borderRadius: '999px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Image
            src='/img/avatar.JPG'
            alt='photo'
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '999px',
              objectFit: 'cover',
              bg: '#FBFAF2',
            }}
          />
        </Flex>
      </Box>
      <Box>
        <Heading
          variant='text.header2'
          sx={{ mt: '10px', p: 'auto', color: '#fff', textAlign: 'center' }}
        >
          Lê Mạnh Linh
        </Heading>
        <Box
          variant='text.header3'
          sx={{
            mt: '10px',
            mb: 'auto',
            p: 'auto',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          iOS Developer
        </Box>
      </Box>
    </Flex>
  );
}
export function SkillView(sx) {
  return (
    <>
      <Box sx={{ pt: '64px', flexDirection: 'column', alignItems: 'left' }}>
        <Heading variant='header3' sx={{ color: '#fff' }}>
          PROFILE
        </Heading>
        <Box sx={{ width: '52%', height: '4px', bg: '#FFC905', my: '24px' }} />
        <Text variant='header4' sx={{ color: '#fff' }}>
          I have more than{' '}
          {new Date(Date.now()).getUTCFullYear() -
            new Date('2017-03-15T00:47:24.676Z').getUTCFullYear()}{' '}
          years of experience in mobile software development.
          <br />
          <br /> I am passionate about building iOS platform applications with
          rich-feature and good architecture; have been working on many
          different domains: banking, financial, crypto, .. and weather
        </Text>
      </Box>
      <Box sx={{ pt: '64px', flexDirection: 'column', alignItems: 'left' }}>
        <Heading variant='header3' sx={{ color: '#fff' }}>
          SKILL
        </Heading>
        <Box sx={{ width: '52%', height: '4px', bg: '#FFC905', my: '24px' }} />
        <Text variant='header4' sx={{ color: '#fff' }}>
          Expert in Swift, iOS Architecture (MVVM, MVVM-C, REDUX-Swift,
          VIPER...)
          <br /> And iOS Development Tools (Xcode, Instruments, Fastlane, CI/CD
          with Bamboo or Jenkins,...).
          <br />
          <br /> Also have experience with Web Font-end development (Reactjs).
        </Text>
      </Box>
      <Box sx={{ pt: '64px', flexDirection: 'column', alignItems: 'left' }}>
        <Heading variant='header3' sx={{ color: '#fff' }}>
          CONTACT
        </Heading>
        <Box sx={{ width: '52%', height: '4px', bg: '#FFC905', my: '24px' }} />
        <Text variant='header4' sx={{ color: '#fff' }}>
          <Text variant='header5' sx={{ color: '#aaa' }}>
            Email:
          </Text>
          <br />
          <Link
            href={`mailto:lemanhlinhbk@gmail.com?subject=Hello!&body=Hi Linh,`}
            variant='text.header4'
            sx={{
              textAlign: 'left',
              fontWeight: '600',
              textOverflow: 'ellipsis',
              color: '#fff',
              target: '_blank',
            }}
          >
            lemanhlinhbk@gmail.com
          </Link>
        </Text>
        <br />
        <Text variant='header4' sx={{ color: '#fff' }}>
          <Text variant='header5' sx={{ color: '#aaa' }}>
            Phone:
          </Text>{' '}
          <Link
            href={`tel:(+84)984688743`}
            variant='text.header4'
            sx={{
              textAlign: 'left',
              fontWeight: '600',
              textOverflow: 'ellipsis',
              color: '#fff',
              target: '_blank',
            }}
          >
            (+84)984688743
          </Link>
        </Text>
        <br />
        <Text variant='header4' sx={{ color: '#fff' }}>
          <Text variant='header5' sx={{ color: '#aaa' }}>
            Telegram:
          </Text>{' '}
          <Link
            href={`https://t.me/linken2405`}
            variant='text.header4'
            sx={{
              textAlign: 'left',
              fontWeight: '600',
              textOverflow: 'ellipsis',
              color: '#fff',
              target: '_blank',
            }}
          >
            @linken2405
          </Link>
        </Text>
        <br />
        <Text variant='header4' sx={{ color: '#fff' }}>
          <Text variant='header5' sx={{ color: '#aaa' }}>
            Skype:
          </Text>{' '}
          <Link
            href={`skype:linhle9999?chat`}
            variant='text.header4'
            sx={{
              textAlign: 'left',
              fontWeight: '600',
              textOverflow: 'ellipsis',
              color: '#fff',
              target: '_blank',
            }}
          >
            linhle9999
          </Link>
        </Text>
      </Box>
      <Box sx={{ pt: '64px', flexDirection: 'column', alignItems: 'left' }}>
        <DownloadCV />
      </Box>
    </>
  );
}
export function CompanyView({ children, company, duration }) {
  return (
    <Box
      sx={{
        pt: '74px',
        flexDirection: 'column',
        alignItems: 'left',
        pr: '32px',
      }}
    >
      <Box sx={{ textAlign: 'left' }}>
        <Heading variant='header2' sx={{ fontWeight: 800 }}>
          {company}
        </Heading>
        <Heading variant='header3'>{duration}</Heading>
      </Box>
      <Box sx={{ width: '52%', height: '4px', bg: '#FFC905', my: '24px' }} />
      {children}
    </Box>
  );
}

export function DownloadCV() {
  const navigate = useNavigate();

  return (
    <>
      <Link
        href='/linh_le_cv.pdf'
        variant='buttons.appPrimary'
        sx={{
          color: '#fff',
          width: '100%',
          minHeight: '24px',
          p: '12px 24px',
          textDecoration: 'none',
        }}
        target='_blank'
      >
        <Text variant='header5' sx={{ color: '#fff' }}>
          View My Full CV (pdf)
        </Text>
        <br />
      </Link>

      <Box
        sx={{ width: '52%', height: '4px', bg: 'transparent', my: '24px' }}
      />

      <Link
        href='/linh_le_cv.pdf'
        variant='buttons.appPrimary'
        sx={{
          color: '#fff',
          width: '100%',
          minHeight: '24px',
          p: '12px 24px',
          textDecoration: 'none',
        }}
        download
      >
        <Text variant='header5' sx={{ color: '#fff', textDecoration: 'none' }}>
          Download My CV (pdf)
        </Text>
        <br />
      </Link>
      <br />
    </>
  );
}
