// import BlankPage from 'pages/common/BlankPage';
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Flex, NavLink, Theme, ThemeProvider, Box } from "theme-ui";
import BlankPage from "../Pages/BlankPage";
import getAvailableRoutes from "./routers";
import { importedTheme } from "./Theme";
import "./App.css";
export const theme: Theme = importedTheme;

function App() {
  const renderRouter = useCallback(() => {
    const routes = getAvailableRoutes();

    return routes.map((item) => {
      const PageComponent = item.pageComponent;
      return (
        <Route path={item.path} element={<PageComponent />} key={item.path} />
      );
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {/* <ReduxToastr
        timeOut={3000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick
      /> */}
      <Router>
        <Flex
          as="nav"
          sx={{
            minWidth: "100vw",
            mx: "0",
            zIndex: "1000",
            position: "sticky",
            top: 0,

            backgroundColor: "#2b2b2b",
          }}
        >
          <NavLink href="/" p={2}>
            Home
          </NavLink>
          <NavLink href="/cv" p={2}>
            Resume
          </NavLink>
          {/* <div className="dropdown">
            <button className="dropbtn">Dev Tools</button>
            <div className="dropdown-content">
              <NavLink href="/tools/jsonviewer" p={2}>
                JSON Viewer
              </NavLink>

              <NavLink href="/tools/jsonmodel" p={2}>
                JSONMappable Parser
              </NavLink>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn">Utilities</button>
            <div className="dropdown-content">
              <NavLink href="/wl" p={2}>
                Watchlist
              </NavLink>

              <NavLink href="/cal" p={2}>
                Calculator
              </NavLink>
            </div>
          </div> */}
        </Flex>

        <Routes>
          {renderRouter()}
          <Route path="*" element={<BlankPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
